import  BasicExample from './components/NavBar';
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return <div><BasicExample /></div>;
}

export default App;
